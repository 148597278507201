import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React from "react";
import logo from "../../logo.svg";
import { Link } from "react-router-dom";
import { useAuth } from "../../helper";
import { decodeToken } from "react-jwt";

function NavigationBar() {
  const { onLogout } = useAuth();
  const decodedToken = decodeToken(localStorage.getItem('token'));

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand role="button" as={Link} to="/"><img
          alt=""
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        /> Walk of Change</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto me-lg-3">
            <Nav.Link as={Link} to="/">Dashboard</Nav.Link>
          </Nav>
          {(decodedToken && decodedToken.roles.indexOf("Undefined.WalkOfChange:Administrator") >= 0) && (
            <Nav className="me-auto me-lg-3">
              <Nav.Link as={Link} to="/users">Benutzerverwaltung</Nav.Link>
            </Nav>
          )}
          <Nav className="me-auto me-lg-3">
            <Nav.Link href="/documentation" target="_blank">
              API Documentation
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            Eingeloggt als: <Link to="/profile">{decodedToken && decodedToken.iss}</Link>
          </Navbar.Text>
          <Nav className="ms-2">
            <Nav.Link onClick={onLogout}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;