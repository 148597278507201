import * as React from "react";
import logo from "../../logo.svg";

const Datenschutz = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="my-4">
            <a href="/login" className="btn btn-outline-primary">
              <span className="d-flex align-items-center">
                <i className="bi bi-box-arrow-in-left me-2"></i> Login
              </span>
            </a>
          </div>
        </div>
      </div>
      <hr/>
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <h1>Datenschutzerkl&auml;rung Walk of Change</h1>
          <p><strong>Allgemeine Informationen &uuml;ber die Verarbeitung personenbezogener Daten</strong></p>
          <p>Verantwortlicher gem&auml;&szlig; Art. 4 Abs. 7 DS-GVO ist Wiesbadener Institut f&uuml;r Nachfolge-Kultur
            (WINK) e.V., im Folgenden: WINK.&nbsp;</p>
          <p>Unseren Datenschutzbeauftragten erreichen Sie unter den am Ende dieser Datenschutzerkl&auml;rung
            bereitgestellten Daten.</p>
          <p><strong>Personenbezogene Daten</strong></p>
          <p>Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
            nat&uuml;rliche Person beziehen. Als identifizierbar wird eine nat&uuml;rliche Person angesehen, die direkt
            oder
            indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
            Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der
            physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
            Identit&auml;t dieser nat&uuml;rlichen Person sind, identifiziert werden kann. Darunter fallen
            beispielsweise
            Informationen wie Ihr Name, Ihre Anschrift, Ihre Telefonnummer, Ihre Sprache, Ihr Standort, Ihre E-Mail
            Adresse,
            Ihre Bankverbindungen und Ihr Geburtsdatum. &nbsp;</p>
          <p><strong>Verarbeitung personenbezogener Daten</strong></p>
          <p>Eine Verarbeitung von personenbezogenen Daten liegt in jedem mit oder ohne Hilfe automatisierter Verfahren
            ausgef&uuml;hrten Vorgang oder in jeder Vorgangsreihe im Zusammenhang mit personenbezogenen Daten vor. Eine
            Datenverarbeitung ist insbesondere in dem Erheben, dem Erfassen, der Organisation, dem Ordnen, der
            Speicherung,
            der Anpassung, der Ver&auml;nderung, dem Auslesen, dem Abfragen, der Verwendung, der Offenlegung
            durch &Uuml;bermittlung, der Verbreitung oder in einer anderen Form der Bereitstellung, dem Abgleich oder
            der
            Verkn&uuml;pfung, der Einschr&auml;nkung, dem L&ouml;schen oder der Vernichtung personenbezogener Daten zu
            sehen.</p>
          <p>Wir verarbeiten personenbezogene Daten nach den nachstehend dargestellten Vorgaben und Voraussetzungen im
            Rahmen einer automatisierten Verarbeitung basierend auf einer einschl&auml;gigen gesetzlichen
            Erm&auml;chtigungsgrundlage. Der Umfang der Verarbeitung Ihrer personenbezogenen Daten wird durch die
            jeweils
            dargestellten Zwecke begrenzt.</p>
          <p>Eine automatisierte Entscheidungsfindung im Einzelfall einschlie&szlig;lich Profiling gem&auml;&szlig; Art.
            22
            DS-GVO findet nicht statt.</p>
          <p>Wenn wir f&uuml;r die Verarbeitung Ihrer personenbezogenen Daten einen Auftragsverarbeiter einsetzen,
            schlie&szlig;en wir mit diesem einen Auftragsverarbeitungsvertrag ab, der s&auml;mtliche Voraussetzungen des
            Art. 28 DS-GVO erf&uuml;llt.</p>
          <p><strong>Zweck der Verarbeitung von personenbezogenen Daten bei Nutzung unserer App &bdquo;Walk of
            Change&ldquo; (im folgenden: App)</strong></p>
          <p>Bei der Nutzung der App erheben wir personenbezogene Daten, die von Ihrem Endger&auml;t an unseren
            Server &uuml;bermittelt werden. Es handelt sich dabei um die folgenden Daten, die f&uuml;r uns technisch
            erforderlich sind f&uuml;r das stabile und sichere Funktionieren der App:</p>
          <ul>
            <li>IP-Adresse</li>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
            <li>Zugriffsstatus/HTTP-Statuscode</li>
            <li>jeweils &uuml;bertragene Datenmenge</li>
          </ul>
          <p>Rechtsgrundlage ist Art. 6 Abs. 1 Satz 1 lt. f DS-GVO (&bdquo;berechtigtes Interesse&ldquo;).</p>
          <p>Im Rahmen der Nutzung der App werden Daten gesammelt und &uuml;bermittelt, die der Kommunikation zwischen
            Ihnen
            und WINK dienen. Hierzu geh&ouml;ren personenbezogene Daten wie z.B. E-Mail Adresse, Vorname und Nachname.
            Desweiteren werden f&uuml;r Zwecke der wissenschaftlichen Auswertung Daten erhoben, die f&uuml;r eine
            wissenschaftliche Auswertung unerl&auml;sslich sind, wie Alter, Geschlecht, Ausbildung, Rolle in der
            Nachfolge,
            von Ihnen aufgesprochene Audiodateien, sowie Unternehmensdaten wie Gr&uuml;ndungsdatum, Lage,
            Unternehmensgr&ouml;&szlig;e, Branche. Die f&uuml;r die wissenschaftliche Auswertung notwendigen Daten
            werden
            ausschlie&szlig;lich in anonymisierter Form und ausschlie&szlig;lich an wissenschaftliche
            Einrichtungen &uuml;bermittelt, die &uuml;ber ein qualifiziertes Datenschutzmanagement
            gem&auml;&szlig; DSGVO
            verf&uuml;gen.</p>
          <p>Da die App in hohem Ma&szlig;e wissenschaftlichen Zwecken dient, ist Ihre Einwilligung nach Art. 6 Abs. 1
            Satz
            1 lt. a DS-GVO i.V.m. Art. 7 DSGVO erforderlich, um die App nutzen zu k&ouml;nnen. Sie k&ouml;nnen diese
            Einwilligung jederzeit mit Zukunftswirkung per E-Mail oder durch eine Nachricht an die in diesem
            Datenschutzhinweis angegebene Kontaktdaten widerrufen, ohne dass Ihnen daraus Nachteile drohen.</p>
          <p><strong>Dauer der Datenverarbeitung</strong></p>
          <p>Die H&ouml;chstdauer der Speicherung ist abh&auml;ngig davon, welchem Zweck die Datenverarbeitung dient.
            Die im
            Rahmen der App erhobenen Daten l&ouml;schen wir, sobald die Speicherung nicht mehr erforderlich ist (dies
            ist in
            der Regel 24 Monate nach der Speicherung der Fall) oder wir schr&auml;nken die Verarbeitung ein, falls
            gesetzliche Aufbewahrungspflichten bestehen.</p>
          <p>Die gesetzlichen Aufbewahrungspflichten, insbesondere gem&auml;&szlig; &sect; 257 HGB und &sect; 147 AO (6
            bzw.
            10 Jahre), bleiben davon unber&uuml;hrt.</p>
          <p><strong>Empf&auml;nger der personenbezogenen Daten</strong></p>
          <p>Wir &uuml;bermitteln Ihre Daten ausschlie&szlig;lich an wissenschaftliche Einrichtungen, die &uuml;ber ein
            qualifiziertes Datenschutzmanagement gem&auml;&szlig; DSGVO verf&uuml;gen, insbesondere das Institut
            f&uuml;r
            Familienunternehmen und Mittelstand an der WHU Otto Beisheim School of Management in Vallendar unter Leitung
            von
            Frau Prof. Nadine Kammerlander.</p>
          <p>Wenn wir f&uuml;r die Verarbeitung Ihrer personenbezogenen Daten einen Auftragsverarbeiter einsetzen,
            schlie&szlig;en wir mit diesem einen Auftragsverarbeitungsvertrag ab, der s&auml;mtliche Voraussetzungen des
            Art. 28 DSGVO erf&uuml;llt.</p>
          <p>Eine dar&uuml;berhinausgehende &Uuml;bermittlung Ihrer personenbezogenen Daten erfolgt nicht, soweit dies
            nicht
            in diesem Hinweis ausdr&uuml;cklich dargestellt wird.</p>
          <p><strong>Ort der Datenverarbeitungsma&szlig;nahmen</strong></p>
          <p>Die Verarbeitung Ihrer personenbezogenen Daten durch uns findet in Deutschland oder in Mitgliedsstaaten der
            Europ&auml;ischen Union statt.&nbsp;</p>
          <p><strong>Sicherheit / Technische und organisatorische Ma&szlig;nahmen</strong></p>
          <p>Wir treffen alle notwendigen technischen und organisatorischen Ma&szlig;nahmen unter Ber&uuml;cksichtigung
            der
            Vorgaben der Art. 24, 25 und 32 DSGVO, um Ihre personenbezogenen Daten vor Verlust, Zerst&ouml;rung,
            Zugriff,
            Ver&auml;nderung oder Verbreitung durch unbefugte Personen und Missbrauch zu sch&uuml;tzen.</p>
          <p>So beachten wir die rechtlichen Vorgaben zur Pseudonymisierung und Verschl&uuml;sselung personenbezogener
            Daten, zur Vertraulichkeit, Integrit&auml;t, Verf&uuml;gbarkeit und der Belastbarkeit der Systeme und
            Dienste im
            Zusammenhang mit der Verarbeitung, zur Verf&uuml;gbarkeit der personenbezogenen Daten und der
            M&ouml;glichkeit,
            diese bei einem physischen oder technischen Zwischenfall rasch wiederherzustellen sowie zur Einrichtung von
            Verfahren zur regelm&auml;&szlig;igen &Uuml;berpr&uuml;fung, Bewertung und Evaluierung der Wirksamkeit der
            technischen und organisatorischen Ma&szlig;nahmen zur Gew&auml;hrleistung der Sicherheit der
            Verarbeitung.</p>
          <p>Des Weiteren beachten wir auch die Vorgaben des Art. 25 DSGVO im Hinblick auf die Grunds&auml;tze
            des &bdquo;privacy by design&ldquo; (Datenschutz durch Technikgestaltung) und des &bdquo;privacy by
            default&ldquo; (Datenschutz durch datenschutzfreundliche Voreinstellungen).</p>
          <p><strong>Ihre Rechte</strong></p>
          <p>Sie haben ein Recht auf unentgeltliche Auskunft &uuml;ber Ihre personenbezogenen Daten sowie, bei dem
            Vorliegen
            der gesetzlichen Voraussetzungen, ein Recht auf Berichtigung, Sperrung und L&ouml;schung Ihrer Daten, auf
            Einschr&auml;nkung der Verarbeitung, auf Daten&uuml;bertragbarkeit sowie ein Widerspruchsrecht.</p>
          <p>Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabw&auml;gung st&uuml;tzen,
            k&ouml;nnen Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung
            insbesondere nicht zur Erf&uuml;llung eines Vertrags mit Ihnen erforderlich ist. Bei Aus&uuml;bung eines
            solchen
            Widerspruchs bitten wir um Darlegung der Gr&uuml;nde, weshalb wir Ihre personenbezogenen Daten nicht wie von
            uns
            durchgef&uuml;hrt verarbeiten sollten. Im Falle Ihres begr&uuml;ndeten Widerspruchs pr&uuml;fen wir die
            Sachlage
            und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden
            schutzw&uuml;rdigen Gr&uuml;nde aufzeigen, aufgrund derer wir die Verarbeitung fortf&uuml;hren.</p>
          <p>Sie haben zudem die M&ouml;glichkeit, sich bei einer zust&auml;ndigen Aufsichtsbeh&ouml;rde zu beschweren
            (z.B.
            Der Hessische Beauftragte f&uuml;r Datenschutz und Informationsfreiheit Prof. Dr. Alexander Ro&szlig;nagel,
            Gustav-Stresemann-Ring 1, 65189 Wiesbaden, Telefon: +49 611 1408 &ndash; 121, <a
              href="https://datenschutz.hessen.de/">https://datenschutz.hessen.de/</a>&nbsp;</p>
          <p>Bei Fragen zur Verarbeitung Ihrer personenbezogenen Daten sowie bei Fragen bezogen auf die vorstehend
            genannten
            Rechte sowie f&uuml;r deren Geltendmachung ebenso wie bei Anregungen wenden Sie sich bitte an uns:</p>
          <p>Wiesbadener Institut f&uuml;r Nachfolge-Kultur (WINK) e.V.<br/>Sooderstr. 45, 65193 Wiesbaden,
            datenschutz@wink-ev.de</p>
        </div>
      </div>
    </div>);
}

export default Datenschutz;
