import * as React from "react";
import { gql, useQuery } from "@apollo/client";
import SimpleModal from "../../components/Modal";
import { Accordion } from "react-bootstrap";

const GET_USERS = gql`
  query FrontendUsers($query: String, $limit: Int, $skip: Int, $sortBy: String, $order: Order) {
    frontendUsersCount(query: $query)
    frontendUsers(query: $query, limit: $limit, skip: $skip, sortBy: $sortBy, order: $order) {
      token
      data
      creationDateTime
      updatedDateTime
      files {
        filename
        title
        station
        fileCreationDateTime
        url
      }
    }
  }
`;

const Dashboard = (props) => {
  const [limit, setLimit] = React.useState(25);
  const [skip, setSkip] = React.useState(0);
  const [query, setQuery] = React.useState("");

  const itemsPerPage = [5, 10, 25, 50];

  const { loading, data } = useQuery(GET_USERS, {
    variables: {
      limit,
      skip,
      query
    }
  });

  const handleDownload = (token) => () => {
    fetch(`/download?token=${token}`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: new Headers({
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        'Accept': '*/*'
      })
    }).then(res => res.blob())
      .then(blob => {
        var saveData = (function () {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";

          return function (fileName) {
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
          };
        }());

        saveData("stations.zip");
      });
  }

  const get = (object, property) => {
    if (typeof object === "string") {
      object = JSON.parse(object);
    }

    if (object && object.hasOwnProperty(property)) {
      return object[property];
    }

    return null;
  }

  const translateSendAudio = (sendAudio = null) => {
    if (sendAudio === null) {
      return sendAudio;
    }

    return sendAudio ? "Ja" : "Nein";
  }

  const translateMode = (mode = null) => {
    if (mode === null) {
      return mode;
    }

    return "MODE.locationIndependent" === mode ? "Eigener WoC" : "Original WoC";
  }

  const translateLocation = (lat = null, lon = null) => {
    if (lat === null || lon === null) {
      return null;
    }

    return <a target="_blank" href={`https://maps.google.com/?q=${lat},${lon}`}>Google Maps</a>;
  }

  const translateStatus = (status = null) => {
    if (status === null) {
      return status;
    }

    if (status === "guideStarted") {
      return "Pfad gestarted";
    }

    if (status === "guideFinished") {
      return "Pfad beendet";
    }

    return status;
  }

  const dateFormat = (dateString) => {
    const date = new Date(dateString);

    return `${("0" + date.getDate()).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}`;
  }

  return (
    <div className="my-5">
      <div className="d-flex align-items-center mb-4 mb-lg-5">
        <form className="w-100 me-3" role="search" onSubmit={(event) => {
          event.preventDefault();
          setSkip(0);
          setQuery(event.target.search.value);
        }}>
          <div className="input-group">
            <input autoFocus disabled={loading} name="search" type="text" className="form-control"
                   placeholder="Search..."/>
            <div className="input-group-append d-flex">
              <select id="items-per-page" defaultValue={limit} onChange={(event) => {
                if (event.target.value !== "Items per page") {
                  setLimit(event.target.value);
                }
              }} className="form-control custom-select">
                <option>Items per page</option>
                {itemsPerPage.map(number => {
                  return <option key={number} value={number.toString()}>{number.toString()}</option>
                })}
              </select>
            </div>
          </div>
        </form>
      </div>

      {!loading && (
        <Pagination
          totalCount={data.frontendUsersCount}
          onChangeSkip={setSkip}
          limit={limit}
          skip={skip}
          query={query}
        />
      )}
      {loading && (
        <PaginationPlaceholder/>
      )}
      <table className="table table-striped table-responsive mt-2 mt-lg-3 mb-4 mb-lg-5">
        <thead>
        <tr>
          <th>E-Mail</th>
          <th>Status</th>
          <th>Audio Senden</th>
          <th>Modus</th>
          <th>Startpunkt</th>
          <th>Erstelldatum</th>
          <th>Änderungsdatum</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {loading && Array.from({ length: limit }).map((p, index) => {
          return (
            <tr key={`placeholder-${index}`} className="placeholder-wave">
              <td><span className="placeholder bg-secondary col-12"></span></td>
              <td><span className="placeholder bg-secondary col-12"></span></td>
              <td><span className="placeholder bg-secondary col-12"></span></td>
              <td><span className="placeholder bg-secondary col-12"></span></td>
              <td><span className="placeholder bg-secondary col-12"></span></td>
              <td><span className="placeholder bg-secondary col-12"></span></td>
              <td><span className="placeholder bg-secondary col-12"></span></td>
              <td className="text-end">
                <span className="placeholder bg-secondary col-4"></span>
              </td>
            </tr>
          )
        })}
        {!loading && data.frontendUsers.map((user) => {
          return (
            <tr valign="middle" key={user.token}>
              <td>{get(user.data, "mail")}</td>
              <td>{translateStatus(get(user.data, "state"))}</td>
              <td>{translateSendAudio(get(user.data, "sendAudio"))}</td>
              <td>{translateMode(get(user.data, "mode"))}</td>
              <td>{translateLocation(get(user.data, "initLocationLat"), get(user.data, "initLocationLon"))}</td>
              <td>{dateFormat(user.creationDateTime)}</td>
              <td>{dateFormat(user.updatedDateTime)}</td>
              <td className="text-end">
                <div className="d-flex justify-content-end">
                  {user.files.length > 0 && (
                    <>
                      <SimpleModal
                        size="xl"
                        icon={<i className="bi bi-file-earmark-music"></i>}
                        title="Audio Dateien"
                      >
                        <Accordion defaultActiveKey="0" flush>
                          {Array.from({ length: 10 }).map((x, index) => (
                            <Accordion.Item key={`accordion-${index}`} eventKey={index}>
                              <Accordion.Header>Station {index + 1}</Accordion.Header>
                              <Accordion.Body>
                                {user.files.map(({ station, url, filename }) => {
                                  if (station === `station-${index + 1}`) {
                                    return (
                                      <audio key={filename} className="w-100" controls>
                                        <source src={url} type="audio/aac"/>
                                        Your browser does not support the audio element.
                                      </audio>
                                    )
                                  }

                                  return null;
                                })}
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </SimpleModal>
                      <button title="Audio Herunterladen" className="btn btn-outline-primary me-2"
                              onClick={handleDownload(user.token)}>
                        <i className="bi bi-download"></i>
                      </button>
                    </>
                  )}
                  <SimpleModal
                    icon={<i className="bi bi-card-text"></i>}
                    title="Detail Information"
                  >
                  <pre>
                    {JSON.stringify(JSON.parse(user.data), null, 2)}
                  </pre>
                  </SimpleModal>
                </div>
              </td>
            </tr>
          )
        })}
        </tbody>
      </table>
      {loading && (
        <PaginationPlaceholder/>
      )}
      {!loading && (
        <Pagination
          totalCount={data.frontendUsersCount}
          onChangeSkip={setSkip}
          limit={limit}
          skip={skip}
          query={query}
        />
      )}
    </div>
  );
}

export default Dashboard;

const Pagination = ({ totalCount, skip, query, limit, onChangeSkip }) => {
  skip = parseInt(skip);
  limit = parseInt(limit);
  totalCount = parseInt(totalCount);

  if (totalCount <= limit) {
    return <></>;
  }

  const listItemClass = (condition = false, additionalClass = "disabled") => {
    let className = "page-item";

    if (condition) {
      className = `${className} ${additionalClass}`;
    }

    return className;
  }

  let listPoints = Array.from({ length: Math.ceil(totalCount / limit) }).map((x, index) => {
    return (<li key={`pagination-${index}`} className={listItemClass(skip / limit === index, "active disabled")}>
      <button className="page-link" onClick={() => {
        onChangeSkip(index * limit >= totalCount ? totalCount : index * limit)
      }}>{index + 1}</button>
    </li>);
  });

  const maxCount = 3
  if (listPoints.length > maxCount) {
    const moreItem = (key = null) => (<li key={key} className="page-item">
      <button className="page-link disabled">...</button>
    </li>);

    const itemLength = listPoints.length;
    const currentIndex = Math.ceil(skip / limit);
    const start = Math.min(Math.max(currentIndex - 2, 0), itemLength);
    const end = Math.min(Math.max(currentIndex + 3, 0), itemLength);
    let newList = [];

    if (start > 0) {
      newList.push(listPoints[0]);
      newList.push(moreItem("pagination-before"));
    }

    newList = newList.concat(listPoints.slice(start, end));

    if (end < itemLength) {
      newList.push(moreItem("pagination-after"));
      newList.push(listPoints[itemLength - 1]);
    }

    listPoints = newList;
  }

  return (
    <nav className="justify-content-center d-flex" aria-label="...">
      <ul className="pagination">
        <li className={listItemClass(skip === 0)}>
          <button className="page-link" onClick={() => {
            onChangeSkip(skip - limit >= 0 ? skip - limit : 0)
          }}>Previous
          </button>
        </li>
        {listPoints}
        <li className={listItemClass(skip + limit >= totalCount)}>
          <button className="page-link" onClick={() => {
            onChangeSkip(skip + limit >= totalCount ? totalCount : skip + limit)
          }}>Next
          </button>
        </li>
      </ul>
    </nav>
  );
}

const PaginationPlaceholder = () => {
  return (
    <nav className="placeholder-wave justify-content-center d-flex">
      <ul className="pagination">
        <li className="page-item disabled">
          <button className="placeholder page-link">Previous</button>
        </li>
        <li className="page-item disabled">
          <button className="placeholder page-link">1</button>
        </li>
        <li className="page-item disabled">
          <button className="placeholder page-link">2</button>
        </li>
        <li className="page-item disabled">
          <button className="placeholder page-link">3</button>
        </li>
        <li className="page-item disabled">
          <button className="placeholder page-link">Next</button>
        </li>
      </ul>
    </nav>
  );
}