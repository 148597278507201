import React from "react";
import {
  useNavigate,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { PageLayout } from "./components/PageLayout";
import { createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { decodeToken } from "react-jwt";

export const fakeAuth = () => {
  return new Promise((resolve) => {
    setTimeout(() => resolve('2342f2f1d131rf12'), 250);
  });
}

export const getLink = () => {
  let origin = window.location.origin;
  if (origin.indexOf("localhost") >= 0) {
    origin = "https://s220923.undefined.de"
  }

  const httpLink = createHttpLink({
    uri: `${origin}/graphql`,
    credentials: 'same-origin'
  });

  const withToken = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });

  const resetToken = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message === "Not allowed") {
          localStorage.removeItem('token');
          window.location.reload();
        }

        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      }
      );
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  return withToken.concat(resetToken).concat(httpLink);
}

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const AuthContext = React.createContext(null);

export const AuthProvider = ({ initialUsername, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const [username, setUsername] = React.useState(initialUsername);

  const handleLogin = (token) => {
    localStorage.setItem('token', token);

    setToken(token);

    const origin = location.state?.from?.pathname || '/';
    navigate(origin);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
    navigate("/login");
  };

  const value = {
    token, username, onUsername: setUsername, onLogin: handleLogin, onLogout: handleLogout
  };

  return (<AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>);
};

export const ProtectedRoute = ({ children, role }) => {
  const token = localStorage.getItem('token');
  const decodedToken = decodeToken(token);
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }}/>;
  }

  if (!role || (role && decodedToken && decodedToken.roles.indexOf(role) <= 0)) {
    return <PageLayout>{children}</PageLayout>;
  }

  return <Navigate to="/" />;
};
