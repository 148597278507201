import * as React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert } from "react-bootstrap";
import SimpleModal from "../../components/Modal";
import ChangePasswordForm from "../../components/ChangePasswordForm";
import CreateAccountForm from "../../components/CreateAccountForm";

const ACCOUNTS = gql`
  query {
    accounts {
      accountIdentifier
      roles
      creationDate
      lastSuccessfulAuthenticationDate
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($accountIdentifier: String!) {
    deleteAccount(accountIdentifier: $accountIdentifier)
  }
`;

const CREATE_ACCOUNT = gql`
  mutation CreateAccount($accountIdentifier: String!, $password: String!, $roles: [String]!) {
    createAccount(accountIdentifier: $accountIdentifier, password: $password, roles: $roles)
  }
`;

const dateFormat = (dateString) => {
  const date = new Date(dateString);

  return `${("0" + date.getDate()).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}`;
}

const Users = () => {
  const { loading, data } = useQuery(ACCOUNTS);
  const [createAccount] = useMutation(CREATE_ACCOUNT);
  const [deleteAccount] = useMutation(DELETE_ACCOUNT);

  const [successMessage, setSuccessMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleDeleteAccount = (accountIdentifier) => {
    if (window.confirm(`Soll der Account "${accountIdentifier}" wirklich gelöscht werden?`)) {
      return deleteAccount({
        variables: { accountIdentifier }
      }).then(() => {
        window.location.reload();
      });
    }
  }

  const handleCreateAccount = ({ accountIdentifier, password, roles }) => {
    return createAccount({
      variables: {
        accountIdentifier, password, roles
      }
    }).then(() => {
      window.location.reload();
    })
  }

  if (loading) {
    return <div className="text-center my-5">
      <div className="spinner-grow" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  }

  return (
    <div className="my-5">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          {errorMessage && (
            <Alert variant="danger" className="mb-5" onClose={() => setErrorMessage(null)} dismissible>
              <p className="m-0">{errorMessage}</p>
            </Alert>
          )}
          {successMessage && (
            <Alert variant="success" className="mb-5" onClose={() => setSuccessMessage(null)} dismissible>
              <p className="m-0">{successMessage}</p>
            </Alert>
          )}
        </div>
        <div className="col-12">
          <div className="my-3 text-end">
            <SimpleModal icon="Account Erstellen" title="Account Erstellen">
              <CreateAccountForm onCreateAccount={handleCreateAccount}/>
            </SimpleModal>
          </div>
          <table className="table table-striped table-responsive mt-2 mt-lg-3 mb-4 mb-lg-5">
            <thead>
            <tr>
              <th>Identifier</th>
              <th>Roles</th>
              <th>Erstelldatum</th>
              <th>Letzer Login</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {data.accounts.map((account) => <AccountRow
                key={account.accountIdentifier}
                onDeleteAccount={() => handleDeleteAccount(account.accountIdentifier)}
                {...account}
              />
            )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const RESET_PASSWORD = gql`
  mutation ResetUserPassword($accountIdentifier: String!, $password: String!) {
    resetUserPassword(accountIdentifier: $accountIdentifier, password: $password)
  }
`;

const AccountRow = ({ accountIdentifier, roles, creationDate, lastSuccessfulAuthenticationDate, onDeleteAccount }) => {
  const [resetUserPassword] = useMutation(RESET_PASSWORD);

  const handleChangePassword = (password) => {
    return resetUserPassword({
      variables: { accountIdentifier, password }
    }).then(({ data }) => data.resetUserPassword);
  }

  return (
    <tr valign="middle">
      <td>{accountIdentifier}</td>
      <td>{roles.join(", ")}</td>
      <td>{dateFormat(creationDate)}</td>
      <td>{dateFormat(lastSuccessfulAuthenticationDate)}</td>
      <td className="text-end">
        <div className="d-flex justify-content-end">
          <SimpleModal title="Passwort Ändern" size="md" icon={<i className="bi bi-shield-lock"></i>}>
            <ChangePasswordForm onChangePassword={handleChangePassword}/>
          </SimpleModal>
          <button className="btn btn-outline-danger" onClick={onDeleteAccount}>
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default Users;

