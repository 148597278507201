import * as React from 'react';
import { useAuth } from "../../helper";
import logo from '../../logo.svg';
import './index.css';
import { gql, useMutation } from "@apollo/client";
import { Link, Navigate } from "react-router-dom";

const SIGN_IN = gql`
  mutation Login($email: String!, $password: String!, $keepLoggedIn: Boolean!) {
    login(email: $email, password: $password, keepLoggedIn: $keepLoggedIn)
  }
`;

const Login = () => {
  const { onLogin, token, onUsername } = useAuth();

  const [signIn, { loading, error, data }] = useMutation(SIGN_IN);

  if (data) {
    return onLogin(data.login);
  }

  if (error) {
    console.error(error);
  }

  if (token) {
    return <Navigate to="/"/>;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;
    const remember = event.target.remember.checked;

    signIn({
        variables: {
          email: username,
          password,
          keepLoggedIn: remember
        }
      }
    ).then((x) => {
      onUsername(username);
    });
  }

  return (
    <div className="content">
      <main className="form-signin w-100 m-auto">
        <form onSubmit={handleSubmit}>
          <img className="mb-4" src={logo} alt="" width="72" height="57"/>
          <h1 className="h3 mb-3 fw-normal">Walk of Change</h1>

          <div className="form-floating">
            <input name="username" type="email" className="form-control" id="floatingInput"
                   placeholder="name@example.com"/>
            <label htmlFor="floatingInput">Email address</label>
          </div>
          <div className="form-floating">
            <input name="password" type="password" className="form-control" id="floatingPassword"
                   placeholder="Password"/>
            <label htmlFor="floatingPassword">Password</label>
          </div>

          <div className="checkbox mb-3">
            <label>
              <input name="remember" type="checkbox"/> Remember me
            </label>
          </div>
          <button disabled={loading} className="w-100 btn btn-lg btn-primary" type="submit">
            {!loading && "Login"}
            {loading && (
              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            )}
          </button>
          <p className="mt-5 mb-3 text-muted">
            <Link to="/datenschutz">Datenschutz</Link>
          </p>
        </form>
      </main>
    </div>
  );
}

export default Login;
