import * as React from "react";
import { Alert } from "react-bootstrap";

const ChangePasswordForm = ({ onChangePassword }) => {
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const password = event.target.password.value;
    const passwordConfirm = event.target.passwordconfirm.value;

    if (password !== passwordConfirm) {
      return setErrorMessage("Die Passwörter stimmen nicht überein.")
    }

    if (password.length < 6) {
      return setErrorMessage("Das Passwort muss mindestens 6 Zeichen lang sein.")
    }

    setLoading(true);

    onChangePassword(password).then((response) => {
      setSuccessMessage(response);
    }).catch((error) => {
      setErrorMessage(error.toString());
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      {errorMessage && (
        <Alert variant="danger" className="mb-3" onClose={() => setErrorMessage(null)} dismissible>
          <p className="m-0">{errorMessage}</p>
        </Alert>
      )}
      {successMessage && (
        <Alert variant="success" className="mb-3" onClose={() => setSuccessMessage(null)} dismissible>
          <p className="m-0">{successMessage}</p>
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <div className="form-group mb-2">
          <label htmlFor="pw">Neues Passwort</label>
          <input required type="password" id="pw" className="form-control" name="password"/>
          <small id="passwordHelpInline" className="text-muted">
            Muss mindestens 6 Zeichen lang sein.
          </small>
        </div>
        <div className="form-group mb-3 mb-lg-4">
          <label htmlFor="pwc">Neues Passwort wiederholen</label>
          <input required type="password" id="pwc" className="form-control" name="passwordconfirm"/>
        </div>
        <button disabled={loading} className="w-100 btn btn-lg btn-primary" type="submit">
          {!loading && "Absenden"}
          {loading && (
            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          )}
        </button>
      </form>
    </>
  );
}

export default ChangePasswordForm;
