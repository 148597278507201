import * as React from "react";
import { Alert } from "react-bootstrap";

const roles = [
  {
    "value": "Undefined.WalkOfChange:User",
    "label": "User"
  },
  {
    "value": "Undefined.WalkOfChange:Administrator",
    "label": "Administrator"
  },
];

const CreateAccountForm = ({ onCreateAccount }) => {
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const accountIdentifier = event.target.username.value;
    const password = event.target.password.value;
    const roles = [];

    event.target.querySelectorAll('input[type=checkbox]:checked').forEach((checkbox) => {
      roles.push(checkbox.value);
    });

    if (password.length < 6) {
      return setErrorMessage("Das Passwort muss mindestens 6 Zeichen lang sein.")
    }

    if (roles.length === 0) {
      return setErrorMessage("Es muss mindestens eine Rolle ausgewählt sein.")
    }

    setLoading(true);

    return onCreateAccount({ accountIdentifier, password, roles })
      .then((response) => {
        setSuccessMessage(response);
      }).catch((error) => {
        setErrorMessage(error.toString());
      }).finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      {errorMessage && (
        <Alert variant="danger" className="mb-3" onClose={() => setErrorMessage(null)} dismissible>
          <p className="m-0">{errorMessage}</p>
        </Alert>
      )}
      {successMessage && (
        <Alert variant="success" className="mb-3" onClose={() => setSuccessMessage(null)} dismissible>
          <p className="m-0">{successMessage}</p>
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <div className="form-group mb-2">
          <label htmlFor="pw">E-Mail Adresse</label>
          <input type="email" required id="username" className="form-control" name="username"/>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="pw">Neues Passwort</label>
          <input type="password" id="pw" className="form-control" name="password"/>
          <small id="passwordHelpInline" className="text-muted">
            Muss mindestens 6 Zeichen lang sein.
          </small>
        </div>
        <div className="form-group mb-2">
          <h5>Rollen</h5>
          {roles.map(({ value, label }, index) => (
            <div key={value} className="form-check form-check-inline">
              <input multiple={true} name="role" className="form-check-input" type="checkbox" id={`role-${index}`} value={value} />
              <label className="form-check-label" htmlFor={`role-${index}`}>{label}</label>
            </div>
          ))}
        </div>
        <button disabled={loading} className="w-100 btn btn-lg btn-primary" type="submit">
          {!loading && "Absenden"}
          {loading && (
            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          )}
        </button>
      </form>
    </>
  );
}

export default CreateAccountForm;
