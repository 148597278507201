import React from "react";
import NavigationBar from "../NavigationBar";

export const PageLayout = ({ children }) => {

  return <div className="page">
    <header>
      <NavigationBar />
    </header>
    <main className="container">
      {children}
    </main>
  </div>
}
