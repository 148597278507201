import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { AuthProvider, ProtectedRoute } from "./helper"

import Login from "./routes/Login"
import Dashboard from "./routes/Dashboard"
import NoMatch from "./routes/NoMatch"
import Profile from "./routes/Profile";
import Users from "./routes/Users";
import Datenschutz from "./routes/Datenschutz";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route index path="" element={<ProtectedRoute>
            <Dashboard/>
          </ProtectedRoute>}/>
          <Route path="/profile" element={<ProtectedRoute><Profile/></ProtectedRoute>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/datenschutz" element={<Datenschutz/>}/>
          <Route path="/users"
                 element={<ProtectedRoute role="Undefined.WalkOfChange:Administrator"><Users/></ProtectedRoute>}/>
          <Route path="*" status={404} element={<NoMatch/>}/>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
