import * as React from "react";
import logo from "../../logo.svg";

const NoMatch = () => {
  return (
    <div className="content">
      <div className="form-signin w-100 m-auto">
        <div>
          <img className="mb-4" src={logo} alt="" width="72" height="57"/>
        </div>
        <h1 className="mb-4">Fehler 404</h1>
        <p>Die Seite wurde nicht gefunden...</p>
        <a className="w-100 btn btn-lg btn-primary" href="/">Zum Login</a>
      </div>
    </div>
  );
}

export default NoMatch;
