import * as React from "react";
import { gql, useMutation } from "@apollo/client";
import ChangePasswordForm from "../../components/ChangePasswordForm";

const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!) {
    resetPassword(password: $password)
  }
`;

const Profile = () => {
  const [resetPassword] = useMutation(RESET_PASSWORD);

  const handleChangePassword = (password) => {
    return resetPassword({
        variables: {
          password: password,
        }
      }
    ).then(({data}) => data.resetPassword);
  }

  return (
    <div className="my-5">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <ChangePasswordForm onChangePassword={handleChangePassword} />
        </div>
      </div>
    </div>
  );
}

export default Profile;
